<template>
  <div class="create-company-container">
    <div class="content">
      <van-cell-group :border="false">
        <div class="title">
          企业名称
        </div>
        <van-field
          v-model="value"
          maxlength="30"
          placeholder="请输入企业名称（不超过30个字）"
        />
      </van-cell-group>
    </div>
    <div class="button-box">
      <van-button plain type="info" @click="handleBack">取消</van-button>
      <van-button type="info" @click="handleConfirm">确认创建</van-button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { Toast, Dialog } from 'vant'
  import Apis from '@/api/creadit'
  import ApiSign from '@/api/addSign'

  export default {
    data() {
      return {
        value: '',
        inviteComId: undefined,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
        partnerId: state => state.sign.partnerId,
        signMsg: state => state.sign.signMsg,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    mounted() {
      this.inviteComId = window.sessionStorage.getItem('inviteComId')
    },
    methods: {
      async handleConfirm() {
        if (this.value === '') {
          Toast('请输入企业名称')
          return
        }
        Apis.createCom({
          comName: this.value,
          inviteComId: this.isZdd ? this.inviteComId : undefined,
        }).then(async res => {
          this.onReset()
          await this.$store.dispatch('updateCurrentIdentity', res)
          // 是否允许签署方不实名签署 0.不允许 1.允许
          const noCreditSign = this.signMsg
            ? this.signMsg.noCreditSign
            : undefined
          console.log('this.signMsg', this.signMsg)
          const msg =
            noCreditSign === 0
              ? `${this.systemInfo.company}尚未进行企业认证，无法签署，请先完成企业认证！`
              : `创建成功，企业认证后签署的文件才具有法律效力，建议您尽早完成认证。`
          window.sessionStorage.removeItem('inviteComId')
          Dialog.confirm({
            confirmButtonText: '去认证',
            confirmButtonColor: '#1676FF',
            cancelButtonText: '暂不认证',
            title: '提示',
            message: msg,
          })
            .then(() => {
              this.$router.push('AuthSubmit')
            })
            .catch(() => {
              if (noCreditSign === 1) {
                this.addSign()
              } else {
                this.$router.push('My')
              }
            })
        })
      },
      handleBack() {
        this.$router.back()
      },
      onReset() {
        this.value = ''
      },
      // 如果从加入签署页过来，直接加入签署进入详情页
      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        ApiSign.joinSign({
          partnerId: this.$store.state.sign.partnerId,
          comId: this.$store.state.user.userInfo.comId,
        })
          .then(async res => {
            await this.$store.dispatch('fetchUserInfo')
            const { msg, status, data } = res
            if (status === 200) {
              const { businessStatus } = data
              // 0 草稿 1未完成 2 已完成 3已作废
              if (businessStatus === 3) {
                this.$router.push({
                  path: '/signComplete',
                })
                return
              }
              this.$router.push({
                path: '/signdetail',
                query: {
                  businessId: data.businessId,
                },
              })
              return
            }
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {})
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>

<style lang="less" scoped>
  .create-company-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      padding: 20px 16px;
      flex: 1;
      .title {
        font-size: 16px;
        color: #111a34;
      }
      .van-cell {
        box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
        padding: 12px 0;
      }
    }
    .button-box {
      width: 100%;
      height: 56px;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: @safe-area;
      left: 0;
      .van-button {
        width: 48%;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
</style>
